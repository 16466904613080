<template>
  <b-container fluid>
    <b-modal id="my-modal" title="Areas" size="md" hide-footer>
      <div class="text-center">
        <img
          class="img-fluid"
          style="
            max-width: 400px;
            margin-left: 0 auto !important;
            margin-right: 0 auto !important;
          "
          :src="logoEmpresa"
          alt=""
        />
      </div>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>LISTA DE AREAS</b></h4>
          </template>
          <div class="text-right px-4 mt-4 responsive-xl-md">
            <div class="d-flex justify-content-end">
              <vs-button
                icon
                animation-type="vertical"
                success
                class="mx-1 text-right"
                @click="$router.push('Areas_agregar')"
              >
                <i class="fas fa-plus pr-1"></i>
                Agregar Area
                <template #animate>
                  <i class="fas fa-plus"></i>
                </template>
              </vs-button>
            </div>
          </div>
          <!-- button-sm -->
          <div
            class="text-right px-4 mt-4 pb-0 mb-0 responsive-sm"
            style="margin-bottom: -73px !important; z-index: 3 !important"
          >
            <div class="d-flex justify-content-end">
              <vs-button
                success
                class=""
                style="background-color: rgb(54, 153, 100) !important"
                @click="$router.push('Areas_agregar')"
                ><i class="fas fa-plus"></i
              ></vs-button>
            </div>
          </div>
          <!-- end button-sm -->
          <div class="table-responsive px-4 mt-3">
            <table
              id="datatable_areas"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th>Nombre Área</th>
                  <th>Departamento</th>
                  <th>Empresa</th>
                  <!-- <th>Estado</th> -->
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>{{ item.descripcion }}</td>
                  <td>{{ item.departamento.descripcion }}</td>
                  <td>{{ item.empresa.nombre }}</td>
                  <td style="width: 150px !important">
                    <ButtonGroup :Id="item.id" :data="dataIcons"></ButtonGroup>
                  </td>

                  <!-- <td>
                     <img
                       @click="
                         logoEmpresa = item.empresa.urlLogo;
                         $bvModal.show('my-modal');
                       "
                       class="avatar-35"
                       :src="item.empresa.urlLogo"
                       alt=""
                     />
                   </td> -->

                  <!-- <td>{{ item.estado == 1 ? "Activo" : "Inactivo" }}</td> -->

                  <!-- <td>
                    <span class="pointer"
                      ><i
                        @click="$router.push('Areas_editar/' + item.id)"
                        class="fas fa-solid fa-pen px-1 text-success"
                      ></i>
                      <i
                        @click="deleteRow(item.id)"
                        class="fas fa-solid fa-trash px-1 text-danger"
                      ></i>
                    </span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    ButtonGroup,
  },
  name: "DataTable",
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      usuario: {},
      dataIcons: [
        {
          type: "success",
          tooltip: "Editar area",
          title: "",
          value: () => 10,
          iconName: "pen",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.$router.push("Areas_editar/" + Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },

        {
          type: "danger",
          tooltip: "Eliminar registro",
          title: "",
          value: () => 0,
          iconName: "trash",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.deleteRow(Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_areas").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Area/Delete/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        console.log("departamentoss.....................");
        let res = await this.$store.dispatch("hl_get", {
          path: "Area/ListArea/" + this.usuario.empresa.id,
        });
        console.log("post departamento... ", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
